// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'BebasNeue';
    src: url('./shared/fonts/bebas/otfs/woffs/BebasNeueRegular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('./shared/fonts/bebas/otfs/woffs/BebasNeueLight.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('./shared/fonts/bebas/otfs/woffs/BebasNeueBold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'MercuryDisplay';
    src: url("./shared/fonts/mercuryDisplay/otfs/woffs/MercuryDisplay-Bold.woff") format("woff");
    font-weight: 700;
}

$proxima-nova-ex-cn-extra-bld: url("./shared/fonts/proximaNova/otfs/woffs/ProximaNovaExCn-Extrabld.woff") format("woff");
@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('./shared/fonts/proximaNova/otfs/woffs/ProximaNovaRegular.woff') format('woff');
}

@font-face {
    font-family: 'ProximaNovaThin';
    src: url("./shared/fonts/proximaNova/otfs/woffs/ProximaNovaThin.woff") format("woff");
}

@font-face {
    font-family: 'ProximaNovaSemi';
    src: url('./shared/fonts/proximaNova/otfs/woffs/ProximaNovaSemibold.woff') format('woff');
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url("./shared/fonts/proximaNova/otfs/woffs/ProximaNovaBold.woff") format("woff");
}