
@import '../../index';
// @import "../../../colors.scss";

:local {
  .button-container {
    background-color: $orange;
    border-radius: .25rem;
    color: $white;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.375rem;
    margin: auto;
    padding: 1.125rem;
  }
}