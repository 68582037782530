@import '../../index';

:local {

  .container {
    height: 100%;
    margin: 1rem;
    background-color: $dark-blue;
  }

  .headerContainer {
    border-bottom: 1px solid $white-opacity;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
  }

  .admin {
    font-size: 1.5rem;
    border-bottom: 1px solid $white-opacity;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: $white;
  }

  .headerWrapper {
    margin-top: 2rem;
  }

  .logoContainer {
    margin-top: 1rem;
    width: 100%;
  }

  .navItem {
      color: $white;
      font-size: 1.5rem
  }
}
