@import '../../index';

:global {
  .card h5.directory-card-title {
    height: 25px;
    color: $black;
    font-family: "ProximaNovaSemi";
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 1rem;
  }

  .directory-page {
    height: 100vh;
    background-color: $light-gray;
  }

  .card-divider.card-overrides {
    background-color: $white;
    justify-content: left !important;
    padding: 0px;
  }

  .card.card-overrides {
    color: $dark-gray;
    font-size: 1rem;
    margin: 3rem;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.08);
    border: none;
    padding: 24px 24px 48px 24px;
    background-color: $white;
  }

  .card.card-overrides.linking-content-wrapper {
    overflow: initial !important;
  }

  .card.card-overrides.event-details {
    margin-top: 0px;
  }

  .card.card-overrides.event-details > .card-divider {
    border-bottom: 1px solid $horizontal-divider-gray;
  }

  .card .card-section {
    padding: 0px !important;
  }
}