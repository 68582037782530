@import '../../index';

.directory-search-select {
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-family: 'ProximaNovaRegular';
  font-size: 16px;
  height: 44px;
  line-height: 20px;
  margin-right: 10px;
  padding-left: 15px;
  width: 160px;
}

.directory-search-input {
  height: 44px;
  width: 160px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  background-color: white;
  font-family: 'ProximaNovaRegular';
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  padding-left: 15px;
  outline: none;
}

.directory-search-input:disabled {
  background-color: white;
  border: 1px solid #e6e6e6;
}
.directory-search-error-container {
  height: 16;
  margin-left: 300px;
}

.directory-search-error {
  color: red;
  font-family: 'ProximaNovaRegular';
  font-size: 12;
  margin-top: 5;
}

button.small.clear.dropdown.dd-menu-title.search-dropdown {
  outline: 0;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  height: 44px;
  line-height: 20px;
  margin-bottom: 3px;
  margin-right: 10px;
  padding-top: 12px;
  text-align: left;
  width: 160px;
}

button.small.clear.dropdown.dd-menu-title.search-dropdown::after {
  border-top-color: $black;
}

.menu.small.vertical.search-dropdown-menu {
  background-color: white;
  border: 1px solid horizontal-divider-gray;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0 9px 19px 0 rgba(0,0,0,0.08);
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  padding: 0px 5px;
  position: absolute;
  text-align: left;
  width: 160px;
  z-index: 999;
}
.menu.small.vertical.search-dropdown-menu .dd-menu-item {
  color: $black;
  cursor: pointer;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 20px;
  margin: 12px 10px;
  text-align: left;
}
.directory-search-button {
  font-family: 'ProximaNovaBold';
}