@import '../../index';

:local {

  .main-text {
    @extend .text;
    font-size: 1.8125rem;
    line-height: 2.25rem;
  }

  .sub-text {
    @extend .text;
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding-bottom: 3.375rem;
  }

  .text {
    color: $black;
    font-family: 'ProximaNovaRegular';
  }
}