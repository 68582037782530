
@import '../../index';

:local {
	.headerGridContainer {
		background-color: $dark-blue !important;
    padding: 20px 20px 40px;
  }

	.title {
		margin-top: 2rem;
		margin-left: 3rem;
		font-size: 12px;
		line-height: 15px;
		color: $orange;
		font-weight: bold
	}

	.subtitleContainer {
    align-items: flex-end;
    display: flex;
  }

	.subtitle {
    color: #fff !important;	
		margin-left: 3rem;
		font-size: 21px;
		line-height: 25px;
		color: $black;
		font-weight: bold
	}

  .lastUpdated {
    color: #fff !important;	
    font-size: 10px;	
    line-height: 12px;
    margin-bottom: 4px;
    margin-left: 16px;
	}
	
	.userContextContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: 24px;
  }

	.settingsUserActions {
		margin-top: 2rem;
		padding-right: 1rem;
		display: flex;
		justify-content: flex-end;
		color: $black;
		font-size: 1.25rem;
		font-weight: bold;
	}
}

:global {
	.dd-menu-item.header-dropdown-button, .dd-menu-title.header-dropdown-button {
		color: $white !important;
		font-size: 1.25rem !important;
		padding: none;
	}
}
