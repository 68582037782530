@import '../../index';

:global {

  .results-page {
    height: 100vh;
    background-color: $light-gray;
    padding: 3rem;
  }

  .full .fullscreen {
    min-width: 100% !important;
    width: 100% !important;
  }

  .loader h1 {
    display: none;
  }

  .rbis-container {
    background-color: $light-gray;
    float:left;
    max-width: 19%;
    width: 19%
  }
  .results-container {
    background-color: $light-gray;
    float:left;
    max-width: 80%;
    width: 80%
  }

  .interval-divisions {
    margin: 0 10px;
  }

  .rbi-container {
    margin: 0 10px;
    float:left;
    height: 380px;
    max-height: 380px;
    margin: 10px;
    overflow: hidden;
    width: 256px;
  }

  .rbi-container h2 {
    height: 72px
  }

  .rbi-results {
    overflow-y: scroll;
    height: 240px;
    max-height: 240px;
  }
}