@import '../../index';

:local {
  .buttonContainer {
      padding: 20px 20px 0;
  }

  .container {
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.25);
    min-width: 666px;
  }

  .eventRowContainer {
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: inherit;
  }


  .rowContainer {
    width: 100%
  }

  .rowHeaderField {
    border-bottom: 1px solid $black
  }

  .tableContainer {
    margin: auto 20px auto 20px;
  }
}
