
@import '../../index';

.resetContainer {
  color: white;
  min-height: 50px;
  min-width: 550px;
  border-radius: 3px;
}

.Toastify .success {
  @extend .resetContainer;
  background: #5FC2A2;
}

.Toastify .error {
  @extend .resetContainer;
  background:rgb(188, 58, 60);
}

.Toastify .info {
  @extend .resetContainer;
  background:rgb(241,196,0);
}

.Toastify .wrapper {
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
}

.Toastify .content {
  display: flex;
}

.Toastify .icon {
  padding-right: 10px;
  width: 28px;
  color: white;
}

.Toastify .messageWrap .title {
  font-family: 'ProximaNovaBold';
}

.Toastify .messageWrap .subTitle {
  font-size: 12px;
  color: rgba(255,255,255,0.7);
  margin-top: -5px;
}

.Toastify .actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}