
@import '../../index';

.menuPopup {
  position: relative;
  width: 45px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
}

.menuPopup.active {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.menuPopup i {
  cursor: pointer;
  font-size: 21px;
  color: #999999;
  padding-top: 4px;
}

.menuPopup .popupList {
  position: absolute;
  right: 0px;
  top: 33px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  border-radius: 4px;
  background: white;
  z-index: 99;
}

.menuPopup .popupList .item {
  padding: 10px 40px;
  cursor: pointer;
}