@import '../../index';

.event-details-table {
  width: 100%;
}

.event-details-table.events {
  margin-top: 5px;
}

.details-table-header {
  border-bottom: 1px solid $horizontal-divider-gray;
  border-top: 1px solid $horizontal-divider-gray;
  color: rgb(155,155,155);
  font-family: 'ProximaNovaBold';
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0px;
}

.details-table-header .accordion-icon,
.details-table-row .accordion-icon {
  width: 40px;
}

.details-table-row .accordion-icon i {
  padding-right: 22px;
  padding-left: 9px;
  color: rgb(155,155,155);
}

.details-table-row .accordion-icon.open i {
  padding-right: 16px;
}

.details-table-row {
  position: relative;
  color: $black;
  font-family: 'ProximaNovaSemi';
  font-size: 16px;
  line-height: 25px;
  padding: 5px 0px 0px;
}

.details-table-row:not(:last-child) {
  border-bottom: 1px solid $horizontal-divider-gray;
}

.details-table-row svg.link {
  cursor: pointer;
  right: -4px;
  position: absolute;
  top: -13px;
}

.details-table-row.clickable {
  cursor: pointer;
}

.details-table-row.selected {
  border-bottom: none;
}

.details-table-row.last {
  border-bottom: none;
}

.details-table-row-menu {
  text-align: center;
  display: flex;
  justify-content: center;
}

.event-action-button {
  display: inline-block;
  margin-left: 19px;
  vertical-align: middle;
}

button.small.clear.add-event-button {
  outline: 0;
  background-color: #fcfcfc;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 12px;
  height: 21px;
  line-height: 20px;
  padding: 0px 6px;
  text-align: center;
  width: 128px;
}

button.small.clear.dropdown.dd-menu-title.event-dropdown {
  outline: 0;
  background-color: #fcfcfc;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 12px;
  height: 21px;
  line-height: 20px;
  padding: 0px 0px 0px 6px;
  text-align: left;
  width: 128px;
}

button.small.clear.dropdown.dd-menu-title.event-dropdown::after {
  border-top-color: $black;
  margin: 0px 6px;
  top: 7px;
}

.menu.small.vertical.event-dropdown-menu {
  background-color: white;
  border: 1px solid horizontal-divider-gray;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0 9px 19px 0 rgba(0,0,0,0.08);
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 12px;
  padding: 0px 8px;
  position: absolute;
  text-align: left;
  width: 144px;
}
.menu.small.vertical.event-dropdown-menu .dd-menu-item {
  color: $black;
  cursor: pointer;
  font-family: ProximaNovaRegular;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 5px;
  text-align: left;
}

.add-event-input {
  height: 21px;
  width: 128px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  background-color: #fcfcfc;
  font-family: 'ProximaNovaRegular';
  font-size: 12px;
  line-height: 20px;
  padding-left: 6px;
  outline: none;
}

.event-children-content {
  width: 100%;
  border-radius: 5px;
  background: rgba(248,249,253,0.6);
  padding: 30px;
}