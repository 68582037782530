@import '../../index';

.error-card {
  min-height: 542px
}

.error-content {
  text-align: center;
}

.error-image {
  width: 126px;
  height: 129px;
  margin-top: 113px;
}

.error-text {
  font-family: 'ProximaNovaSemi';
  font-size: 21px;
  line-height: 25px;
  color: #4a4a4a;
  margin-top: 28px;
}

.error-subtext {
  font-family: 'ProximaNovaRegular';
  font-size: 14px;
  line-height: 18px;
  color: #9b9b9b;
  margin-top: 13px;
}