@import "~foundation-sites/scss/foundation";
@import './fonts.scss';
@import'./variables-colors.scss';
@import "images";
@include foundation-everything;
p {
    font-size: 1rem;
    line-height: 1.3;
}

li {
    list-style: none;
}

a {
    opacity: .75;
    text-decoration: none;
}

a:hover {
    opacity: 1;
    text-decoration: none;
    cursor: pointer;
}

// Overwrites default react-select placeholder style
.Select-placeholder {
    font-size: 1.2rem;
    top: .7rem;
}

body {
    position: relative;
    color: $gray-dark;
    height: 100vh;
    background-color: $background-gray;
    font-family: 'ProximaNovaRegular';
}

.container {
    margin: 5%;
}

hr {
    border-color: $horizontal-divider-gray;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0
}

h1 {
    color: $black;
    font-weight: normal;
    font-size: 2rem;
}

h2 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
    color: $black;
}

h3 {
    margin: 0;
    font-size: .9rem;
    font-weight: 700;
    color: $orange;
}

h4 {
    margin: 0;
    font-size: .7rem;
    font-weight: 700;
    color: $lighter-steel-gray;
    letter-spacing: .03rem;
}

h5 {
    font-weight: 600;
    font-size: 1.2rem;
}

h6 {
    margin: 0;
    font-size: .7rem;
    font-weight: 400;
    color: #fff;
}

input[type=text] {
    background-color: #FBFBFB;
    border-radius: .25rem;
}

input[type=password] {
    background-color: #FBFBFB;
    border-radius: .25rem;
}

label {
    color: $black;
    font-size: .8rem
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.callout.alert {
    border-color: #C70000;
    color: #C70000;
}

.textCenter {
    text-align: center
}

.inputError[type=text] {
    background-color: #f7e4e1;
    border-color: #C70000;
    color: #C70000;
}

.inputError[type=password] {
    background-color: #f7e4e1;
    border-color: #C70000;
    color: #C70000;
}

.errorText {
    color: #C70000;
}

.modalBackdrop {
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    transition: background-color 400ms;
}

.modalContainer {
    background-color: $white;
    margin-top: 2rem;
    padding: 2rem;
    top: 15%;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    max-width: 720px;
    border-radius: 5pt;
    box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.40);
    animation-duration: 250ms;
    animation-name: fadeIn;
    animation-timing-function: ease-out;
}

.modalErrorSummary {
    border-radius: 3px;
    background-color: #CA504C;
    color: #fff;
    text-align: center;
    padding: .75rem
}

.grid-container {
    display: table-cell;
    vertical-align: middle;
}

.grid-container-wrapper {
    display: table;
    text-align: center;
    width: 100%;
}

.sidebar {
    background: $dark-blue;
    min-height: 100vh;
    min-width: 225px !important;
}

.back {
    padding-left: 2.4rem;
    padding-top: 20px;
}

.back a {
    display: flex;
    align-items: center;
    color: black !important;
    font-weight: 600;
    font-size: 1.2rem;
}