@import '../../index';

.details-header {
  position: relative;
  width: 100%;
}

.details-header .action-buttons {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.details-header .action-buttons.top {
  margin-bottom: 8px;
}

.event-details-action-button {
  display: inline-block;
  width: 184px;
  margin-left: 19px;
  vertical-align: middle;
}