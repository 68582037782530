@import "../../index.scss";

:local {
  .athlinks-flame {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .content-callout-container {
    background-color: $white;
    border-radius: .1875rem;
    box-shadow: 0 .125rem .5rem 0 rgba(0,0,0,0.2);
    margin-top: 5rem;
    padding: 2rem;
  }

  .content-container {
    background-color: $background-gray;
    padding-bottom: 9.5rem;
  }

  .content-list {
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: 0;
  }

  .content-text {
    color: $black;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .content-text-wrapper {
    margin-top: 1rem;
  }

  .content-title {
    color: $black;
    font-size: 2rem;
    line-height: 3.9rem;
  }

  .content-title-margin {
    margin-top: 2.25rem;
  }

  .content-title-wrapper {
    margin-top: 4.5rem;
  }

  .footer-container {
    background-color: $dark-blue;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .proposition-header {
    align-items: center;
    background-image: url(../../shared/images/proposition-background.jpeg);
    background-position: center;
    background-size: cover;
    color: white;
    min-height: 95vh;
    text-align: center;
  }

  .proposition-subtitle {
    color: white;
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }

  .proposition-title {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.4375rem;
  }

  .sign-in-button {
    align-items: center;
    text-align: center;
  }

  .sign-in-button-container {
    margin-top: 4.5rem;
  }

  .sign-in-button-text {
    color: $white;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.375rem;
  }
}