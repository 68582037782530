@import '../../index';

.action-button-wrapper {
  height: 35px;
  border: 1px solid $horizontal-divider-gray;
  border-radius: 3px;
  color: $black;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'ProximaNovaBold';
  line-height: 17px;
  padding: 10px;
  cursor: pointer;
}

.action-button-wrapper.primary {
  background-color: #2C314E;
  color: $white;
}

.action-button-text {
  float: right;
  margin-left: 13px;
}

.action-button-wrapper i {
  height: 13px;
}

.action-button-wrapper svg {
  height: 17px;
  margin-top: -2px;
  fill: rgb(74,74,74)
}

.action-button-wrapper.disabled {
  color: $horizontal-divider-gray;
  cursor: not-allowed;
}

.action-button-wrapper.primary.disabled {
  color: $white;
  background-color: #DDDDDD;
  cursor: not-allowed;
}

.action-button-wrapper.disabled svg {
  fill: $horizontal-divider-gray;
  animation: rotate 1.5s linear infinite; 
}

@keyframes rotate{
  to{ transform: rotate(360deg); }
}