@import '../../index';

:local {
  .eventsPageContainer {
    overflow: hidden;
  }

  .sidebar {
      background: $dark-blue;
      height: 100vh;
  }
}
