@import '../../index';

:local {
  .container {
    width: 100%;
  }
}

%details {
  color: black;
  font-family: Helvetica;
  font-size: .7rem;
  font-weight: 400;
  letter-spacing: .03rem;
}

:local(.details) {
  @extend %details;
}

:local(.detailsSubdued) {
  @extend %details;
  color: rgb(171, 171, 171);
}

:local(.detailsLink) {
  @extend %details;
  color: #1390bf
}

:local(.detailsLink) {
  @extend %details;
  color: #1390bf
}

:local(.styleDetailsSubdued) {
  margin-top: 20px;
}

:local(.title) {
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
  color: black;
  font-family: Helvetica;
}
