@import '../../../index';

.add-event-body {
  position: relative;
  height: 100%;
}

.add-event-search {
  height: 44px;
}

.add-event-input {
  height: 44px;
  width: 424px;
  border: 1px solid #DDDDDD;
  border-radius: 3px 0px 0px 3px;
  background-color: #FBFBFB;
  font-family: 'ProximaNovaRegular';
  font-size: 16px;
  line-height: 20px;
  padding-left: 20px;
  margin-left: 8px;
  outline: none;
}

.add-event-search-button {
  font-size: 14px;
  line-height: 17px;
  height: 44px;
  width: 123px;
  border-radius: 0px 3px 3px 0px;
  padding: 15px;
}

.add-event-searching {
  text-align: center;
  margin-top: 75px;
  font-family: 'ProximaNovaSemi';
  font-size: 16px;
  line-height: 25px;
}

.add-event-response {
  height: 98px;
  margin-top: 25px;
  padding: 23px 21px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.add-event-response-name {
  font-family: 'ProximaNovaSemi';
  font-size: 16px;
  line-height: 25px;
  color: #4a4a4a;
}

.add-event-response-column {
  margin-left: 25px;
}

.add-event-response-header {
  font-family: 'ProximaNovaRegular';
  font-size: 11px;
  line-height: 20px;
  color: #9b9b9b;
}

.add-event-response-data {
  font-family: 'ProximaNovaRegular';
  font-size: 12px;
  line-height: 20px;
  color: #4a4a4a;
}

.date-location-divider {
  padding: 0px 10px;
}

.add-event-message {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-family: 'ProximaNovaRegular';
  font-size: 14px;
  line-height: 18px;
}

.add-event-message-event-name {
  font-family: 'ProximaNovaBold'
}

button.small.clear.dropdown.dd-menu-title.add-event-dropdown {
  outline: 0;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  height: 44px;
  line-height: 20px;
  margin-bottom: 3px;
  margin-right: 10px;
  padding-top: 12px;
  text-align: left;
  width: 239px;
}

button.small.clear.dropdown.dd-menu-title.add-event-dropdown::after {
  border-top-color: $black;
}

.menu.small.vertical.add-event-dropdown-menu {
  background-color: white;
  border: 1px solid horizontal-divider-gray;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0 9px 19px 0 rgba(0,0,0,0.08);
  color: $black;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  padding: 0px 5px;
  position: absolute;
  text-align: left;
  width: 239px;
}

.menu.small.vertical.add-event-dropdown-menu .dd-menu-item {
  color: $black;
  cursor: pointer;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 20px;
  margin: 12px 10px;
  text-align: left;
}