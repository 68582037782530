@import '../../index';

:global {

  .compare-reg-page {
    height: 100vh;
    background-color: $light-gray;
  }

  .refresh-controls {
    margin: 3rem;
  }

  .loader h1 {
    display: none;
  }

}