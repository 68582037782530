@import '../../index';

.modal-outer {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

.modal-inner {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 25px 0px;
  width: 874px;
  height: 506px;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
}

.modal-header {
  background-color: #f8f9fd;
  height: 64px;
  position: relative;
}

.modal-title {
  color: #4a4a4a;
  text-align: center;
  font-family: 'ProximaNovaBold';
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 24px 0px;
}

.modal-close-icon {
  position: absolute;
  top: 15px;
  right: 23px;
  height: 18px;
  width: 14px;
  color: #9b9b9b;
  cursor: pointer;
}

.modal-body {
  height: 336px;
  overflow-y: scroll;
  padding: 44px 35px 0px 35px;
}

.modal-footer {
  background-color: $white;
  display: flex;
  justify-content: space-around;
  height: 106px;
  padding: 21px 33px 30px 33px;
}

.modal-button {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  height: 55px;
  width: 395px;
  vertical-align: middle;
}

.modal-button.action {
  margin-left: 18px;
}