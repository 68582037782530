
@import '../../index';

:local {
  .ddMenu {
    margin: none;
    display: flex;
    flex-direction: column;
  }
}

:global {
  .dd-menu-title div {
    display: inline-block;
  }
}

