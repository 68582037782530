$athlinks-blue-light: rgb(26, 152, 218);
$athlinks-blue: rgb(22, 169, 225);
$background-gray: rgb(248, 249, 253);
$black: rgb(74, 74, 74);
$box-shadow: rgba(0, 0, 0, 0.5);
$clear: rgba(0, 0, 0, 0);
$dark-blue-hover: rgb(61, 67, 105);
$dark-blue: rgb(44,49,78);
$delete-button-black: rgb(34, 34, 34);
$error-red: rgb(188, 58, 60);
$gray-dark: rgb(66, 66, 66);
$gray-light: rgb(207, 207, 207);
$horizontal-divider-gray: rgb(221, 221, 221);
$job-hover: rgb(32, 38, 73);
$lighter-steel-gray-transparent: rgba(200, 200, 200, 0.7);
$lighter-steel-gray: rgb(171, 171, 171);
$link-blue: rgb(0, 146, 201);
$off-white-darker: rgb(210, 210, 210);
$off-white: rgb(249, 249, 249);
$orange-active: rgb(141, 44, 3);
$orange-hover: rgb(219, 69, 4);
$orange: rgb(255,107,0);
$steel-gray: rgb(150, 150, 150);
$success-green: rgb(95, 194, 162);
$test-color: rgb(235, 85, 27);
$warning-yellow: rgb(229, 192, 0);
$white: rgb(255, 255, 255);
$white-opacity-label: rgba(255, 255, 255, 0.2);
$white-opacity: rgba(255, 255, 255, 0.6);
$white-transparent: rgba(255, 255, 255, 0);
$ellipsis-gray: rgb(235,235,235);
