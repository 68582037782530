@import '../../index';

.missing-event-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.missing-event-header .add-to-directory-button {
  border: 1px solid $black;
  color: $black;
  margin: 0;
  position: relative;
  top: -10px;
}