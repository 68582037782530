@import '../../index';

.button-container {
  font-family: 'ProximaNovaBold';
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  height: 55px;
  padding: 18px;
  border-radius: 3px;
  color: $white;
  background-color: #2C314E;
  cursor: pointer;
}

.button-container.secondary {
  color: #4a4a4a;
  background-color: $white;
  border: solid 1px #9B9B9B;
}

.button-container.disabled {
  background-color: #DDDDDD;
  cursor: not-allowed;
}