@import '../../index';

.event-details-event-name {
  margin-top: 16px;
  font-size: 31px;
  font-family: 'ProximaNovaBold';
}

.details-title {
  position: relative;
  color: $black;
  font-family: 'ProximaNovaBold';
  font-size: 21px;
  margin-top: 16px;
}